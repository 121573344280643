<script setup lang="ts">
import { IonContent, IonPage, isPlatform, modalController } from '@ionic/vue'
import { NativeBiometric } from 'capacitor-native-biometric'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import PhoneCode from '~/components/PhoneCode.vue'
import { useMainStore } from '~/stores/main'
import type { definitions } from '~/types/supabase'

const router = useRouter()
const { t } = useI18n()
const biometricAv = ref(false)
const main = useMainStore()
const biometric = computed(
  () => biometricAv.value && main.auth?.user_metadata?.security?.biometric,
)
const user = ref<definitions['users']>()
const errorMessage = ref('')

const hide = () => {
  modalController.dismiss()
}

const submit = async (code: string) => {
  if (code === main.pin) {
    if (isPlatform('capacitor')) {
      await NativeBiometric.setCredentials({
        username: 'com.neufmoispourtoi.app.code',
        password: main.pin,
        server: 'com.neufmoispourtoi.app.code',
      })
    }
    hide()
  }
  else {
    errorMessage.value = t('lock.wrongCode')
  }
}

const forgotCode = async () => {
  await main.logout()
  router.replace('/login')
  hide()
}

onMounted(async () => {
  if (isPlatform('capacitor')) {
    try {
      const res = await NativeBiometric.isAvailable()
      biometricAv.value = res.isAvailable
      // if (!biometricAv.value || main.biometricRunning)
      //   return
      // const listener = await App.addListener('appStateChange', async(state) => {
      //   // Comment to hide lockscreen in dev
      //   // console.log('appStateChange pinlock', state)
      //   if (state.isActive) {
      //     listener.remove()
      //     try {
      //       main.biometricRunning = false
      //       await NativeBiometric.verifyIdentity({
      //         reason: t('biometric.reason'),
      //         title: t('biometric.title'),
      //       })
      //       setTimeout(() => {
      //         main.biometricRunning = false
      //       }, 1500)
      //       hide()
      //     }
      //     catch (err) {
      //       console.log(err)
      //     }
      //   }
      // })
    }
    catch (err) {
      console.log(err)
    }
  }
})
</script>

<template>
  <IonPage>
    <IonContent :fullscreen="true">
      <div class="p-8 mt-16 lg:mt-0">
        <h1 v-if="user" class="text-3xl">
          {{ `${main.user?.first_name} ${main.user?.last_name}` }}
        </h1>
        <p class="mt-2 text-sweet-pink-500">
          {{ t("lock.mine") }}
        </p>
      </div>
      <div class="grid place-content-center w-full p-8">
        <PhoneCode
          :biometric="biometric"
          :error-message="errorMessage"
          @submit-code="submit"
        />
      </div>
      <div
        class="ion-margin-top w-full inline-block align-center font-medium text-center text-sm text-bright-cerulean-500 hover:text-black"
        @click="forgotCode"
      >
        {{ t("lock.forgot") }} ?
      </div>
    </IonContent>
  </IonPage>
</template>
