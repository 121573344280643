import { isPlatform } from '@ionic/vue'
import type { User } from '@supabase/supabase-js'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { NativeBiometric } from 'capacitor-native-biometric'
import type { PurchaserInfo } from 'capacitor-purchases'
import type { definitions } from '../types/supabase'
import { useSupabase } from '../services/supabase'
import { unregisterToken } from '../services/notifications'
import { isAnyActiveSub } from '../services/iap'

const basicPlans = [
  'app.neufmoispourtoi.monthly',
  'app.neufmoispourtoi.quarterly',
  'app.neufmoispourtoi.biannual',
  'app.neufmoispourtoi.yearly',
]

export const useMainStore = defineStore('main', () => {
  const biometricRunning = ref(false)
  const path = ref('')
  const chatFilter = ref('active')
  const displayCode = ref(false)
  const paid = ref<PurchaserInfo | null>()
  const professional = ref<Boolean | null>(null)
  const auth = ref<User | null>(null)
  const user = ref<definitions['users'] | null>(null)
  const enableNotifications = computed<boolean>(() => {
    return !!(user.value && user.value?.enableNotifications)
  })
  const pin = ref<string | null>(null)
  const enableSecurityCode = computed<boolean>(() => {
    return auth.value && auth.value?.user_metadata?.security?.biometric
  })

  const isPayingMode = computed((): boolean => {
    if (!paid.value)
      return false
    return (isAnyActiveSub(basicPlans, paid.value)
    )
  })
  const logout = async () => {
    return new Promise<void>((resolve) => {
      const supabase = useSupabase()
      unregisterToken()
      supabase.auth.onAuthStateChange(async (event) => {
        if (event === 'SIGNED_OUT') {
          pin.value = null
          auth.value = null
          user.value = null
          professional.value = null
          if (isPlatform('capacitor')) {
            try {
              await NativeBiometric.deleteCredentials({
                server: 'com.neufmoispourtoi.app.code',
              })
              await NativeBiometric.deleteCredentials({
                server: 'com.neufmoispourtoi.app',
              })
            }
            catch (err) {
              console.log('err delete credfentials', err)
            }
          }
          resolve()
        }
      })
      localStorage.removeItem('supabase.auth.token')
      setTimeout(() => {
        supabase.auth.signOut()
      }, 300)
    })
  }
  return {
    paid,
    isPayingMode,
    chatFilter,
    biometricRunning,
    professional,
    enableNotifications,
    enableSecurityCode,
    auth,
    user,
    path,
    pin,
    displayCode,
    logout,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMainStore, import.meta.hot))
