import { modalController } from '@ionic/vue'
import PinLock from '~/components/PinLock.vue'
import { useMainStore } from '~/stores/main'

export const showLocker = async (): Promise<HTMLIonModalElement | null> => {
  const main = useMainStore()
  if (main.displayCode || !main.auth || !main.pin)
    return null
  // console.log('showLocker')
  main.displayCode = true
  const modal = await modalController.create({
    component: PinLock,
    backdropDismiss: false,
    showBackdrop: false,
    swipeToClose: false,
  })
  await modal.present()
  await modal.onDidDismiss()
  // console.log('hideLocker')
  main.displayCode = false
  return modal
}
